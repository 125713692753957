export default {
  title: "Gérez votre abonnement facilement et immédiatement",
  online: "Notre support en ligne",
  form: "Formulaire de contact",
  email: "E-mail *",
  yourEmail: "Votre e-mail",
  topic: "Sujet *",
  selectTopic: "Sujet de la demande",
  payment: "Question sur un prélèvement",
  subscription: "Abonnement et résiliation",
  refund: "Remboursement",
  other: "Autre",
  message: "Message *",
  yourMessage: "Votre message",
  send: "Envoyer",
  satisfaction: "Votre satisfaction est notre priorité",
  experts:
    "Nos experts sont disponibles pour répondre à vos questions 24/7. Contactez nos agents et recevez une réponse adaptée sous 48h.",
  complaint:
    "En cas de réclamation sur une commande passée, merci de renseigner l’adresse e-mail utilisée pour la commande.",
  satisfy: "Nos experts font le maximum pour vous satisfaire.",
  unsubscribe: "Se désabonner",
  home: "Accueil",
  rights: "Tous droits réservés, 2020",
  sent: "Votre demande a bien été envoyée !",
  languages : "Langues",
  cookies : "En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de vos données personnelles (par des cookies ou technologies similaires) afin de vous proposer des contenus et des cervices adaptés à vos centres d'intérêts",
  legals : "Mentions légales",
  invalid : "Requête invalide. Rééssayez plus tard ou contatez un administrateur",
  error : "Erreur lors de l'envoi de la demande, merci de réessayer"
};
