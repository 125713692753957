import { Button, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Headset from "./assets/headset.svg";
import LogoEn from "./assets/logo_en.png";
import Logo from "./assets/logo.svg";
import contact from "./assets/contact.svg";
import LanguagePicker from "./LanguagePicker";
import { LocaleContext } from "./LocaleContext";
import { useCookies } from "react-cookie";
const Footer = () => {
  const classes = useStyles();
  const { locale, texts } = useContext(LocaleContext);
  const [cookies, setCookie] = useCookies();
  return (
    <>
      {!cookies.cookies && (
        <div className={classes.cookies}>
          <p>{texts.cookies}</p>
          <Button
            onClick={() => {
              setCookie("cookies", true);
            }}
            variant="solid"
            className={classes.button}
          >
            OK
          </Button>
        </div>
      )}

      <div className={classes.wrapper}>
        <div className={classes.satisfaction}>
          <img alt="headset" src={Headset} />
          <div>
            <h4>{texts.satisfaction}</h4>
            <p>{texts.experts}.</p>
            <p>{texts.complaint}</p>
            <p>{texts.satisfy}</p>
          </div>
        </div>
        <div className={classes.footer}>
          <Link to="/" className={classes.name}>
            <img
              alt="Certificat-gage.com"
              src={locale === "fr" ? Logo : LogoEn}
            />
          </Link>
          <div>
            <p className={classes.accueil}>{texts.rights}</p>
            <div>
              <a
                href="mailto:contact@certificat-gage.com"
                className={classes.link}
              >
                contact@certificat-gage.com
              </a>
            </div>
            <a
              href="https://certificat-gage.com/mentions-legales"
              target="_blank"
              className={classes.link}
              rel="noreferrer"
            >
              {texts.legals}
            </a>
          </div>
          <LanguagePicker />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "auto",
    minHeight: 200,
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  footer: {
    flex: 1,
    backgroundColor: "#688CCD",
    color: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
    flexWrap: "wrap",
    marginTop: 20,
  },
  satisfaction: {
    flex: 3,
    color: "#1f4382",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
    textAlign: "left",
    "& h4": {
      fontSize: 24,
      margin: "10px 0",
    },
    "& p": {
      margin: 0,
    },
    "& img": {
      width: 100,
      marginTop: 10,
    },
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      textAlign: "center",
    },
  },
  name: {
    fontSize: 42,
    textDecoration: "none",
    fontWeight: "bold",
    color: "#FFF",
    margin: "20px 0",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
  },
  accueil: {
    fontSize: 20,
    fontWeight: "500",
    margin : 0
  },
  link: {
    color: "#FFF",
  },
  cookies: {
    backgroundColor: "#1f4382",
    color: "#ffffff",
    padding: 20,
    border: "1px solid #ffffff",
    position: "sticky",
    bottom: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    color: "#FFF",
    width: 40,
    height: 40,
  },
}));
export default Footer;
