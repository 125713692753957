import React, { createContext, useState, useEffect } from "react";
import en from "./i18n/en";
import fr from "./i18n/fr";

const messages = {
  en: en,
  fr: fr,
};

export const LocaleContext = createContext();

const LocaleContextProvider = (props) => {
  const [texts, setTexts] = useState("");
  const [locale, setLocale] = useState(() => {
    const localData = localStorage.getItem("locale");
    let res;
    if (localData) {
      res = JSON.parse(localData);
    } else {
      const language = navigator.language.split(/[-_]/)[0];
      res = messages.hasOwnProperty(language) ? language : "en";
    }
    return res;
  });

  useEffect(() => {
    setTexts(messages[locale])
    localStorage.setItem("locale", JSON.stringify(locale));
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale,  texts}}>
        {props.children}
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;