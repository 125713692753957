import logo from "./logo.svg";
import "./App.css";
import Header from "./Header";
import Form from "./Form";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Unsubscribe from "./Unsubscribe";
import { useContext } from "react";
import { LocaleContext } from "./LocaleContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { reCaptchaKey } from "./API";

function App() {
  const { locale } = useContext(LocaleContext);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language={locale}>
      <div className="App">
        <Router>
          <Header></Header>
          <Switch>
            <Route exact path="/">
              <Unsubscribe />
            </Route>
            <Route path="/">
              <Redirect to="/" />
            </Route>
          </Switch>

          <Footer></Footer>
        </Router>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
