export default {
  title: "Manage your subscriptions easily and immediately",
  online: "Our online support",
  form: "Contact Form",
  email: "Email *",
  yourEmail: "Your email address",
  topic: "Topic *",
  selectTopic: "Select a topic",
  payment: "Question about a payment",
  subscription: "Subscription and cancellation",
  refund: " Refund",
  other: "Other",
  message: "Message *",
  your_message: "Your message",
  send: "Send",
  satisfaction: "Your satisfaction is our priority",
  experts:
    "Our experts are available to answer your questions 24/7. Contact our agents and receive an adapted answer within 48 hours.",
  complaint:
    "In case of a complaint about an order placed, please fill in the e-mail address used for the order.",
  satisfy: "Our experts do their best to satisfy you.",
  unsubscribe: "Unsubscribe",
  home: "Home",
  rights: "All Rights Reserved, 2020",
  sent: "Your request has been sent !",
  languages: "Languages",
  cookies : "By continuing to browse this site, you accept the use of your personal data (by cookies or similar technologies) in order to offer you content and services tailored to your areas of interest.",
  legals : "Legal Notice",
  invalid : "Invalid request. Try again or contact an administrator",
  error : "An error occurred, please try again"
};
    
    
    
    
    
    
    
    
    
   
    
    
    
    
    
    
    
    