import React, { useContext, useState } from "react";
import { LocaleContext } from "./LocaleContext";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const LanguagePicker = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (locale) => {
    setLocale(locale);
    setAnchorEl(null);
  };

  const { setLocale, texts } = useContext(LocaleContext);
  return (
    <>
      <p
        onClick={handleClick}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        {texts.languages}{" "}
        <i className="fas fa-sort-up" style={{ marginBottom: "0" }}></i>
      </p>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose("en");
          }}
        >
          English
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose("fr");
          }}
        >
          Français
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguagePicker;
