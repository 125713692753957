import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LocaleContext } from "./LocaleContext";
import Logo from "./assets/logo.svg";
import LogoEn from "./assets/logo_en.svg";
import contact from "./assets/contact.svg";
const Header = () => {
  const { texts, locale } = useContext(LocaleContext);
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Link className={classes.name} to="/">
          <img
            alt="Certificat-gage.com"
            src={locale === "fr" ? Logo : LogoEn}
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Link to="/" className={classes.accueil}>
            {texts.home}
          </Link>
          <br />
          {/* <Link to="/unsubscribe" className={classes.accueil}>
            {texts.unsubscribe}
          </Link>{" "} */}
          <img alt="contact@certificat-gage.com" src={contact} />
        </div>
      </div>
      <div className={classes.subtitle}>
        <h2 className={classes.title}>{texts.title}</h2>
        <p>{texts.online}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "auto",
    minHeight: 200,
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    flex: 3,
    borderBottomWidth: 15,
    borderBottomColor: "#688CCD",
    borderBottomStyle: "solid",
    color: "#1f4382",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
    flexWrap: "wrap",
  },
  subtitle: {
    color: "#1f4382",
    flex: 9,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "15%",
    paddingRight: "15%",
    flexWrap: "wrap",
    "& p": {
      marginTop: -10,
      fontSize: 18,
    },
  },
  name: {
    fontSize: 42,
    textDecoration: "none",
    fontWeight: "bold",
    color: "#1f4382",
    margin: "20px 0",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
  },
  accueil: {
    fontSize: 24,
    fontWeight: "bold",
    textDecoration: "none",
    color: "#1f4382",
    margin: 5,
  },
}));
export default Header;
