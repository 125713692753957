import { Button, makeStyles, Select, TextField } from "@material-ui/core";
import React, { useCallback, useContext, useState } from "react";
import Background from "./assets/background.png";
import { useSnackbar } from "notistack";
import { LocaleContext } from "./LocaleContext";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import RequestAPI from "./API";

const Form = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { texts } = useContext(LocaleContext);
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [topic, setTopic] = useState();
  const [message, setMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState("");
  const [requests, setRequests] = useState(0);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      enqueueSnackbar(texts.invalid, {
        variant: "error",
      });
      return;
    }

    const result = await executeRecaptcha(topic + requests);
    setToken(result);

    RequestAPI("POST", "support", {
      token: token,
      email: email,
      topic: topic,
      message: message,
      domain: window.location.hostname,
    })
      .then((res) => {
        enqueueSnackbar(texts.sent, {
          variant: "success",
        });
        setRequests((prev) => prev + 1);
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          enqueueSnackbar(texts.invalid, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(texts.error, {
            variant: "error",
          });
        }

        return;
      });
  };

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  return (
    <div className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.formTitle}>
          <h3>{texts.form}</h3>
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <label htmlFor="email">{texts.email}</label>
            <TextField
              variant="outlined"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={classes.input}
              placeholder={texts.yourEmail}
              fullWidth
            />
          </div>
          <div className={classes.field}>
            <label htmlFor="topic">{texts.topic}</label>
            <Select
              native
              variant="outlined"
              name="topic"
              required
              value={topic}
              style={{ color: !topic && "#688CCD" }}
              onChange={(e) => {
                setTopic(e.target.value);
              }}
              className={classes.input}
              fullWidth
            >
              <option value="" style={{ color: "#688CCD" }}>
                {texts.selectTopic}
              </option>
              <option value={texts.payment}>{texts.payment}</option>
              <option value={texts.subscription}>{texts.subscription}</option>
              <option value={texts.refund}>{texts.refund}</option>
              <option value={texts.other}>{texts.other}</option>
            </Select>
          </div>
        </div>
        <div className={classes.textArea}>
          <label htmlFor="message">{texts.message}</label>
          <TextField
            variant="outlined"
            name="message"
            required
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            className={classes.input}
            fullWidth
            multiline
            rows={5}
            placeholder={texts.yourMessage}
          />
        </div>
        <GoogleReCaptcha action="support" onVerify={handleReCaptchaVerify} />
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          type="submit"
        >
          {texts.send}
        </Button>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "20px 20%",
    background: `no-repeat url(${Background})`,
    backgroundSize: "cover",
    minHeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    flex: 1,
    backgroundColor: "rgba(255,255,255,0.5)",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.75)",
    "& label": {
      marginBottom: 5,
      fontWeight: "bold",
      fontSize: 18,
    },
  },
  formTitle: {
    color: "#FFF",
    backgroundColor: "#1f4382",
    textAlign: "left",
    paddingLeft: 15,
  },
  fields: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  field: {
    width: "100%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  input: {
    backgroundColor: "#FFF",
    borderRadius: 5,

    "& input::placeholder": {
      opacity: 1,
      color: "#688CCD",
    },
  },
  textArea: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& textarea::placeholder": {
      color: "#688CCD",
      opacity: 1,
    },
  },
  button: {
    height: 75,
    width: 250,
    margin: 20,
    alignSelf: "flex-end",
    backgroundColor: "#1f4382",
    textTransform: "none",
    fontSize: 20,
  },
  snackbar: {
    backgroundColor: "#4caf50",
  },
}));
export default Form;
