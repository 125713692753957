import axios from "axios";

//export const URL = "http://localhost:8000/api";
export const URL = "https://certificat-gage.com/api/";

export const reCaptchaKey = "6LdLZlMaAAAAAEtGWiCWC3jXJCCmMsNN722kosG8"

export const axiosInstance = axios.create({
  baseURL: URL,
  timeout: 8000,
  responseType: "json",
  headers: { "X-Custom-Header": "foobar" },
});

export default function RequestAPI(method = "GET", url = "/", data = []) {
  return axios({
    method: method,
    url: URL + url,
    data: data,
    responseType: "json",
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}