import { Button, makeStyles, TextField } from "@material-ui/core";
import React, { useContext, useState, useCallback } from "react";
import Background from "./assets/background.png";
import { useSnackbar } from "notistack";
import { LocaleContext } from "./LocaleContext";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import RequestAPI from "./API";

const Unsubscribe = () => {
  const { texts } = useContext(LocaleContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const classes = useStyles();
  const [email, setEmail] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      enqueueSnackbar(texts.invalid, {
        variant: "error",
      });
      return;
    }

    const result = await executeRecaptcha("subscription");
    setToken(result);

    RequestAPI("POST", "subscription", {
      token: token,
      email: email,
      domain: window.location.hostname,
    })
      .then((res) => {
        enqueueSnackbar(texts.sent, {
          variant: "success",
        });
      })
      .catch((e) => {
        enqueueSnackbar(texts.invalid, {
          variant: "error",
        });
        return;
      });
  };
  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );
  return (
    <div className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.formTitle}>
          <h3>{texts.unsubscribe}</h3>
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <label htmlFor="email">{texts.email}</label>
            <TextField
              variant="outlined"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={classes.input}
              placeholder={texts.yourEmail}
              fullWidth
            />
          </div>
        </div>
        <GoogleReCaptcha
          action="subscription"
          onVerify={handleReCaptchaVerify}
        />
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          type="submit"
        >
          {texts.send}
        </Button>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "20px 20%",
    background: `no-repeat url(${Background})`,
    backgroundSize: "cover",
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    flex: 1,
    backgroundColor: "rgba(255,255,255,0.5)",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.75)",
    "& label": {
      marginBottom: 5,
      fontWeight: "bold",
      fontSize: 18,
    },
  },
  formTitle: {
    color: "#FFF",
    backgroundColor: "#1f4382",
    textAlign: "left",
    paddingLeft: 15,
  },
  fields: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  field: {
    width: "100%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  input: {
    backgroundColor: "#FFF",
    borderRadius: 5,

    "& input::placeholder": {
      opacity: 1,
      color: "#688CCD",
    },
  },
  textArea: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& textarea::placeholder": {
      color: "#688CCD",
      opacity: 1,
    },
  },
  button: {
    height: 75,
    width: 250,
    margin: 20,
    alignSelf: "flex-end",
    backgroundColor: "#1f4382",
    textTransform: "none",
    fontSize: 20,
  },
  snackbar: {
    backgroundColor: "#4caf50",
  },
}));
export default Unsubscribe;
